.welcome_screen {
  width: 100vw;
  height: 100vh;
  background-color: #000;
  color: #20c20e;
  overflow: hidden;
}

.welcome_screen > h1 {
  color: #20c20e;
  text-shadow: -3px 0px 35px rgba(57, 255, 20, 0.5);
  animation: endingText 2s forwards;
  animation-delay: 16s;
}

@keyframes endingText {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(20);
    filter: blur(20px);
    opacity: 0.4;
  }
}
