*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  text-decoration: none;
  outline: none;
}

.flex_display {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex_start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}