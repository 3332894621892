.home {
    width: 100vw;
    min-height: 100vh;
    background-color: #000;
}

.home_background {
    width: 100vw;
    height: 100vh;
    position: fixed;
    color: #20c20e;
    text-shadow: -3px 0px 35px rgba(57, 255, 20, 0.5);
    transform: scale(15);
    filter: blur(20px);
    opacity: 0.6;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

